import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { VexRoutes } from '../@vex/interfaces/vex-route.interface';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { AuthGuardService as AuthGuard } from './pages/pages/auth/auth-guard.service';

const routes: VexRoutes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/pages/auth/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/pages/auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('./pages/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
  },
  {
    path: 'ConfirmRegistration',
    loadChildren: () => import('./pages/pages/auth/confirm-registration/confirm-registration.module').then(m => m.ConfirmRegistrationModule),
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },

  {
    path: 'apps',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],

    children: [
      {
        path: 'analytics',
        loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
      },
      
     
          {
            path: 'Dashboard',
            loadChildren: () => import('./pages/apps/dashboard/dashboard.module').then(m => m.DashboardModule),
          },
          {
            path: 'chat',
            loadChildren: () => import('./pages/apps/chat/chat.module').then(m => m.ChatModule),
            data: {
              toolbarShadowEnabled: true
            }
          },
          {
            path: 'mail',
            loadChildren: () => import('./pages/apps/mail/mail.module').then(m => m.MailModule),
            data: {
              toolbarShadowEnabled: true,
              scrollDisabled: true
            }
          },
          {
            path: 'social',
            loadChildren: () => import('./pages/apps/social/social.module').then(m => m.SocialModule)
          },
          {
            path: 'contacts',
            loadChildren: () => import('./pages/apps/contacts/contacts.module').then(m => m.ContactsModule)
          },
          {
            path: 'calendar',
            loadChildren: () => import('./pages/apps/calendar/calendar.module').then(m => m.CalendarModule),
            data: {
              toolbarShadowEnabled: true
            }
          },
          {
            path: 'aio-table',
            loadChildren: () => import('./pages/apps/aio-table/aio-table.module').then(m => m.AioTableModule),
          },
          {
            path: 'hotel',
            loadChildren: () => import('./pages/apps/hotel/hotel.module').then(m => m.HotelModule),
          },  
           {
            path: 'room',
            loadChildren: () => import('./pages/apps/room/room.module').then(m => m.RoomModule),
          },   
          {
            path: 'board',
            loadChildren: () => import('./pages/apps/board/board.module').then(m => m.BoardModule),
          }, 
           {
            path: 'country',
            loadChildren: () => import('./pages/apps/country/country.module').then(m => m.CountryModule),
          },  
          {
            path: 'villes',
            loadChildren: () => import('./pages/apps/villes/villes.module').then(m => m.VillesModule),
          },   
          {
            path: 'contrat',
            loadChildren: () => import('./pages/apps/contrat/contrat.module').then(m => m.ContratModule),
          },
          {
            path: 'child-price',
            loadChildren: () => import('./pages/apps/child-price/child-price.module').then(m => m.ChildPriceModule),
          },
          {
            path: 'inscription',
            loadChildren: () => import('./pages/apps/inscription/inscription.module').then(m => m.InscriptionModule),
          },
          {
            path: 'presence',
            loadChildren: () => import('./pages/apps/presence/presence.module').then(m => m.PresenceModule),
          },
          {
            path: 'conferencier',
            loadChildren: () => import('./pages/apps/conferencier/conferencier.module').then(m => m.ConferencierModule),
          },
          {
            path: 'avance',
            loadChildren: () => import('./pages/apps/avance/avance.module').then(m => m.AvanceModule),
          },
          {
            path: 'caution',
            loadChildren: () => import('./pages/apps/caution/caution.module').then(m => m.CautionModule),
          },
          {
            path: 'avance-fournisseur',
            loadChildren: () => import('./pages/apps/avance-fournisseur/avance-fournisseur.module').then(m => m.AvanceFournisseurModule),
          },
          {
            path: 'caution-fournisseur',
            loadChildren: () => import('./pages/apps/caution-fournisseur/caution-fournisseur.module').then(m => m.CautionFournisseurModule),
          }, 
          {
            path: 'en-tete',
            loadChildren: () => import('./pages/apps/en-tete/en-tete.module').then(m => m.EnTeteModule ),
          },

          {
            path: 'reservations',
            loadChildren: () => import('./pages/apps/reservations/reservations.module').then(m => m.ReservationsModule),
          },
          {
            path: 'recu',
            loadChildren: () => import('./pages/apps/recu/recu.module').then(m => m.RecuModule),
          },
          {
            path: 'Mini ERP',
            loadChildren: () => import('./pages/apps/mini-erp/mini-erp.module').then(m => m.MiniErpModule),
          },
          {
            path: 'xml-in',
            loadChildren: () => import('./pages/apps/xml-in/xml-in.module').then(m => m.XmlInModule),
          },
          {
            path: 'search',
            loadChildren: () => import('./pages/apps/search/search.module').then(m => m.SearchModule),
          },
          {
            path: 'searching',
            loadChildren: () => import('./pages/apps/searching/searching.module').then(m => m.SearchingModule),
          },
          {
            path: 'xml-in-integrateur',
            loadChildren: () => import('./pages/apps/xml-in-integrateurs/xml-in-integrateurs.module').then(m => m.XmlInIntegrateursModule),
          },
          {
            path: 'Mappage',
            loadChildren: () => import('./pages/apps/mappage-xml/mappage-xml.module').then(m => m.MappageXmlModule),
          },
          {
            path: 'Mapping',
            loadChildren: () => import('./pages/apps/mapping/mapping.module').then(m => m.MappingModule),
          },
          {
            path: 'XML-out',
            loadChildren: () => import('./pages/apps/xml-out/xml-out.module').then(m => m.XmlOutModule),
          },
          {
            path: 'Groups',
            loadChildren: () => import('./pages/apps/groups/groups.module').then(m => m.GroupsModule),
          },
          {
            path: 'users',
            loadChildren: () => import('./pages/apps/users/users.module').then(m => m.UsersModule),
          },
          {
            path: 'Groupes',
            loadChildren: () => import('./pages/apps/groupes/groupes.module').then(m => m.GroupesModule),
          },
          {
            path: 'help-center',
            loadChildren: () => import('./pages/apps/help-center/help-center.module').then(m => m.HelpCenterModule),
          },
          {
            path: 'scrumboard',
            loadChildren: () => import('./pages/apps/scrumboard/scrumboard.module').then(m => m.ScrumboardModule),
          },
          {
            path: 'editor',
            loadChildren: () => import('./pages/apps/editor/editor.module').then(m => m.EditorModule),
          },


           
        ]
      },
      {
        path: 'pages',
        component: CustomLayoutComponent,
    canActivate: [AuthGuard],
        children: [
          {
            path: 'pricing',
            loadChildren: () => import('./pages/pages/pricing/pricing.module').then(m => m.PricingModule)
          },
          {
            path: 'faq',
            loadChildren: () => import('./pages/pages/faq/faq.module').then(m => m.FaqModule)
          },
          {
            path: 'guides',
            loadChildren: () => import('./pages/pages/guides/guides.module').then(m => m.GuidesModule)
          },
          {
            path: 'invoice',
            loadChildren: () => import('./pages/pages/invoice/invoice.module').then(m => m.InvoiceModule)
          },
          {
            path: 'error-404',
            loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
          },
          {
            path: 'error-500',
            loadChildren: () => import('./pages/pages/errors/error-500/error-500.module').then(m => m.Error500Module)
          }
        ]
      },
      {
        path: 'ui',
        component: CustomLayoutComponent,
    canActivate: [AuthGuard],
        children: [
          {
            path: 'components',
            loadChildren: () => import('./pages/ui/components/components.module').then(m => m.ComponentsModule),
          },
          {
            path: 'forms/form-elements',
            loadChildren: () => import('./pages/ui/forms/form-elements/form-elements.module').then(m => m.FormElementsModule),
            data: {
              containerEnabled: true
            }
          },
          {
            path: 'forms/form-wizard',
            loadChildren: () => import('./pages/ui/forms/form-wizard/form-wizard.module').then(m => m.FormWizardModule),
            data: {
              containerEnabled: true
            }
          },
          {
            path: 'icons',
            loadChildren: () => import('./pages/ui/icons/icons.module').then(m => m.IconsModule)
          },
          {
            path: 'page-layouts',
            loadChildren: () => import('./pages/ui/page-layouts/page-layouts.module').then(m => m.PageLayoutsModule),
          },
        ]
      },
      {
        path: 'documentation',
        loadChildren: () => import('./pages/documentation/documentation.module').then(m => m.DocumentationModule),
      },
      {
        path: '**',
        loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
      }
    ]
;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule, QuicklinkModule]
})
export class AppRoutingModule {
}
